:root {
  --body-background: #f2f2f2;
  --body-color: #444;
  --heading-color: black;
  --action-color: #d64045;
}

body {
  background: var(--body-background);
  color: var(--body-color);
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
  margin: 0 8px;
  font-size: 108%;
  line-height: 1.5;
}

a {
  color: var(--action-color);
  text-decoration: underline;
  text-decoration-color: #ffb088;
}

h1 {
  margin: 1rem 0 3rem;
  text-align: center;
  font-weight: 900;
  font-size: 2.5rem;
  color: var(--heading-color);
  line-height: 1.2;
}

body > header {
  margin: 1rem;
  text-align: center;
}

body > header img {
  display: inline-block;
  width: 400px;
  max-width: 100%;
}

body > nav {
  margin: 2rem 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  font-size: 1.3rem;
  font-weight: bold;
}

img {
  max-width: 100%;
  height: auto;
}

.navbar {
  margin: 2rem 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  font-size: 1.3rem;
  font-weight: bold;
}

body > nav a {
  text-decoration: none;
}

main {
  margin: 2rem auto 4rem;
  max-width: 65rem;
  min-height: calc(100vh - 200px);
  padding: 25px 25px 50px;
  background: white;
  box-shadow: 2px 3px 3px #ddd;
  border-radius: 3px;
}

footer {
  text-align: center;
  margin-bottom: 4rem;
  font-size: 1em;
}

hr {
  border: none;
  border-top: 2px dotted #bbb;
  margin: 3rem 0;
}

/* The dropdown container */
.dropdown {
  float: left;
  overflow: hidden;
}

/* Dropdown button */
.dropdown {
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
}

/* Add a red background color to navbar links on hover */
.navbar a:hover, .dropdown:hover {
  background-color: red;
  color: white;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
  background-color: red;
}

/* Show the dropdown menu on hover */
.navbar-dropdown:hover .dropdown-content {
  display: block;
}

code {
    background-color: #f5f5f5;
    color: #da1039;
    font-weight: normal;
    padding: .25em .5em .25em;
}
